import { Grid, Typography } from "@mui/material";
import { common } from "@mui/material/colors";
import { useNavigate } from "react-router-dom";
import React from "react";

export type LicenseType = {
  name: string;
  logo: string;
  description?: string;
  requirements?: string[];
  exams?: string[];
};

export type ComponentProps = {
  license: LicenseType;
};

const License = ({ license }: ComponentProps) => {
  const navigate = useNavigate();

  return (
    <Grid
      sx={{
        backgroundColor: "primary.main",
        width: "90%",
        maxWidth: "369px",
        maxHeight: "155",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        padding: 3,
        paddingBottom: 2,
        borderRadius: 3,
        cursor: "pointer",
      }}
      onClick={() => navigate(`/carnet/${license.name}`)}
    >
      <img
        src={license.logo}
        alt={license.name}
        style={{ maxHeight: "100px", maxWidth: "90%" }}
      />
      <Typography
        color={common.white}
        sx={{ fontWeight: 700, lineHeight: "35px", fontSize: 30 }}
      >
        {license.name}
      </Typography>
    </Grid>
  );
};

export default React.memo(License);
