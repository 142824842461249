import { createTheme } from "@mui/material";

declare module "@mui/material/styles" {
  interface Theme {
    status: {
      danger: string;
    };
  }
  // allow configuration using `createTheme`
  interface ThemeOptions {
    status?: {
      danger?: string;
    };
  }
}

const theme = createTheme({
  palette: {
    primary: {
      main: "#da0c29",
      light: "#e96656",
    },
    secondary: {
      main: "#1e9e6b",
    },
  },
  typography: {
    allVariants: {
      color: "#404040",
      fontFamily: '"Lato", Helvetica, sans-serif',
    },
    h1: {
      lineHeight: "40px",
      fontSize: 30,
      paddingTop: "15px",
      paddingBottom: "10px",
      textTransform: "uppercase",
      fontWeight: 700,
    },
    h2: {
      fontSize: 32,
      textAlign: "center",
    },
    h3: {
      fontSize: 26,
      textAlign: "center",
    },
    h4: {
      fontSize: 22,
      textAlign: "center",
    },
    h5: {
      fontSize: 18,
      textAlign: "center",
    },
    body1: {
      textAlign: "center",
      color: "#939393",
      fontSize: "18px",
      lineHeight: "25px",
    },
    body2: {
      textAlign: "center",
      color: "#939393",
      fontSize: "14px",
      lineHeight: "25px",
    },
  },
});

export default theme;
