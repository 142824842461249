import { Link, Typography } from "@mui/material";
import Grid from "@mui/material/Grid";
import { useNavigate } from "react-router-dom";
import React from "react";

const Footer = () => {
  const navigate = useNavigate();

  return (
    <Grid
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: 2,
        backgroundColor: "#272727",
        paddingLeft: 3,
        paddingTop: 8,
        paddingBottom: 5,
        paddingRight: 3,
      }}
    >
      <Typography>
        <Link
          color={"#939393"}
          sx={{ cursor: "pointer" }}
          onClick={() => {
            navigate("/avis-legal");
          }}
        >
          Avís legal
        </Link>
      </Typography>
      <Typography>
        <Link
          color={"#939393"}
          sx={{ cursor: "pointer" }}
          onClick={() => {
            navigate("/condicions-generals-de-contractacio");
          }}
        >
          Condicions generals de contractació
        </Link>
      </Typography>
      <Typography>
        <Link
          color={"#939393"}
          sx={{ cursor: "pointer" }}
          onClick={() => {
            navigate("/politica-de-cookies");
          }}
        >
          Política de Cookies
        </Link>
      </Typography>
      <Typography>
        <Link
          color={"#939393"}
          sx={{ cursor: "pointer" }}
          onClick={() => {
            navigate("/politica-de-privadesa-i-proteccio-de-dades");
          }}
        >
          Política de privadesa i protecció de dades
        </Link>
      </Typography>
    </Grid>
  );
};

export default React.memo(Footer);
