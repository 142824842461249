import React from "react";
import PageContainer from "../pageContainer";
import { Grid, LinearProgress, Typography } from "@mui/material";
import OptionsList from "../licensePage/list/OptionsList";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";

const LegalAdvicePage = () => {
  const companyData = [
    "Denominació social : Autoscola Tramuntana SL",
    "Nom Comercial : Autoescola Tramuntana",
    "Domicili Social : Cl Riera Ginjolers 94, 17480 Roses, Girona",
    "NIF : B17430695",
    "Telèfons : 972 254 451 / 649 393 069",
    "e-Mail : tramuntana1@telefonica.net",
    "Nom del domini : https://autoescolatramuntana.com",
  ];

  return (
    <PageContainer>
      <Grid sx={{ padding: 5 }}>
        <Typography variant="h1" sx={{ textAlign: "" }}>
          Avís legal
        </Typography>
        <LinearProgress variant="determinate" value={100} />
        <OptionsList
          title="Dades de l’empresa"
          options={companyData}
          Icon={<ArrowForwardIosIcon />}
        />
        <Typography variant="h2" sx={{ textAlign: "left", paddingTop: 4 }}>
          Objecte
        </Typography>
        <Typography
          variant="body1"
          sx={{ paddingTop: 2, textAlign: "justify" }}
        >
          El prestador, responsable del lloc web, posa a disposició dels usuaris
          el present document amb què pretén acomplir a les obligacions
          disposades en la llei 34/2002, de Serveis de la Societat de la
          Informació i del Comerç Electrònic (LSSI-CE), així com informar a tots
          els usuaris del lloc web respecte a quines són les condicions d’ús del
          lloc web.
        </Typography>

        <Typography
          variant="body1"
          sx={{ paddingTop: 2, textAlign: "justify" }}
        >
          Tota persona que accedeixi a aquest lloc web assumeix el paper
          d’usuari, comprometent-se a l’observança i compliment rigorós de les
          disposicions aquí disposades, així com a qualsevol altra disposició
          legal que fora d’aplicació.
        </Typography>

        <Typography
          variant="body1"
          sx={{ paddingTop: 2, textAlign: "justify" }}
        >
          El prestador es reserva el dret a modificar qualsevol tipus
          d’informació que pogués aparèixer al lloc web, sense que hi hagi
          obligació de preavisar o posar en coneixement dels usuaris aquestes
          obligacions, entenent-se com suficient amb la publicació al lloc web
          del prestador.
        </Typography>

        <Typography variant="h2" sx={{ textAlign: "left", paddingTop: 4 }}>
          Responsabilitat
        </Typography>

        <Typography
          variant="body1"
          sx={{ paddingTop: 2, textAlign: "justify" }}
        >
          El prestador s’eximeix de qualsevol tipus de responsabilitat derivada
          de la informació publicada al seu lloc web, sempre que aquesta
          informació hagi estat manipulada o introduïda per un tercer aliè a
          aquest.
        </Typography>
        <Typography
          variant="body1"
          sx={{ paddingTop: 2, textAlign: "justify" }}
        >
          Des del lloc web del client és possible que es redirigeixi a
          continguts de tercers llocs web. Atès que el prestador no pot
          controlar sempre els continguts introduïts pels tercers en els seus
          llocs web, aquest no assumeix cap tipus de responsabilitat respecte a
          aquests continguts. En tot cas, el prestador manifesta que procedirà a
          la retirada immediata de qualsevol contingut que pogués contravenir la
          legislació nacional o internacional, la moral o l’ordre públic,
          procedint a la retirada immediata de la redirecció a l’esmentat lloc
          web, posant en coneixement de les autoritats competents el contingut
          en qüestió.
        </Typography>
        <Typography
          variant="body1"
          sx={{ paddingTop: 2, textAlign: "justify" }}
        >
          El prestador no es fa responsable de la informació i continguts
          emmagatzemats, a títol enunciatiu, en fòrums, xat, generadors de
          blocs, comentaris, xarxes socials o qualsevol altre mitjà que permeti
          a tercers publicar continguts de forma independent en la pàgina web
          del prestador. No obstant això i en compliment del que disposa l’art.
          11 i 16 de la LSSI-CE, el prestador es posa a disposició de tots els
          usuaris, autoritats i forces de seguretat, i col·laborant de forma
          activa en la retirada o si escau bloqueig de tots aquells continguts
          que puguin afectar o contravenir la legislació nacional, o
          internacional, drets de tercers o la moral i l’ordre públic. En cas
          que l’usuari consideri que existeix en el lloc web algun contingut que
          pogués ser susceptible d’aquesta classificació, es prega que ho faci
          saber de forma immediata a l’administrador del lloc web.
        </Typography>
        <Typography
          variant="body1"
          sx={{ paddingTop: 2, textAlign: "justify" }}
        >
          Aquest lloc web ha estat revisat i provat perquè funcioni
          correctament. En principi, pot garantir-se el correcte funcionament
          els 365 dies de l’any, 24 hores al dia. No obstant això, el prestador
          no descarta la possibilitat que existeixin certs errors de
          programació, o que esdevinguin causes de força major, catàstrofes
          naturals, vagues, o circumstàncies semblants que facin impossible
          l’accés a la pàgina web.
        </Typography>

        <Typography variant="h2" sx={{ textAlign: "left", paddingTop: 4 }}>
          Propietat Intel·lectual i industrial
        </Typography>
        <Typography
          variant="body1"
          sx={{ paddingTop: 2, textAlign: "justify" }}
        >
          El lloc web, incloent-hi a títol enunciatiu la seva programació,
          edició, compilació i altres elements necessaris per al seu
          funcionament, els dissenys, logotips, text i / o gràfics són propietat
          del prestador o si escau disposa de llicència o autorització expressa
          per part dels autors. Tots els continguts del lloc web es troben
          degudament protegits per la normativa de propietat intel·lectual i
          industrial.
        </Typography>
        <Typography
          variant="body1"
          sx={{ paddingTop: 2, textAlign: "justify" }}
        >
          Independentment de la finalitat per a la qual fossin destinats, la
          reproducció total o parcial, ús, explotació, distribució i
          comercialització, requereix en tot cas de l’autorització escrita
          prèvia per part del prestador. Qualsevol ús no autoritzat prèviament
          per part del prestador serà considerat un incompliment greu dels drets
          de propietat intel·lectual o industrial de l’autor.
        </Typography>
        <Typography
          variant="body1"
          sx={{ paddingTop: 2, textAlign: "justify" }}
        >
          Els dissenys, logotips, text i / o gràfics aliens al prestador i que
          poguessin aparèixer en el lloc web, pertanyen als seus respectius
          propietaris, sent ells mateixos responsables de qualsevol possible
          controvèrsia que pogués suscitar respecte a aquests. En tot cas, el
          prestador compta amb l’autorització expressa i prèvia per part
          d’aquests.
        </Typography>
        <Typography
          variant="body1"
          sx={{ paddingTop: 2, textAlign: "justify" }}
        >
          El prestador reconeix a favor dels seus titulars els corresponents
          drets de propietat industrial i intel·lectual, no implicant el seu sol
          esment o aparició en el lloc web l’existència de drets o
          responsabilitat alguna del prestador sobre aquests, com tampoc
          respatller, patrocini o recomanació per part d’aquest. Per realitzar
          qualsevol tipus d’observació respecte a possibles incompliments dels
          drets de propietat intel·lectual o industrial, així com sobre
          qualsevol dels continguts del lloc web, pot fer-ho a través del
          següent correu electrònic ae.tramuntanaroses@gmail.com.
        </Typography>

        <Typography variant="h2" sx={{ textAlign: "left", paddingTop: 4 }}>
          Llei Aplicable i Jurisdicció
        </Typography>
        <Typography
          variant="body1"
          sx={{ paddingTop: 2, textAlign: "justify" }}
        >
          Per a la resolució de totes les controvèrsies o qüestions relacionades
          amb el present lloc web o de les activitats en ell desenvolupades,
          serà d’aplicació la legislació espanyola, a la qual se sotmeten
          expressament les parts, sent competents per a la resolució de tots els
          conflictes derivats o relacionats amb el seu ús els Jutjats i
          Tribunals de Figueres.
        </Typography>
      </Grid>
    </PageContainer>
  );
};

export default React.memo(LegalAdvicePage);
