import { Typography } from "@mui/material";
import Grid from "@mui/material/Grid";
import { common } from "@mui/material/colors";
import React from "react";

export type SchoolType = {
  name: string;
  map: string;
  address: string;
  email: string;
  schedule: string;
  phone: string;
};

export type ComponentProps = {
  school: SchoolType;
};

const School = ({ school }: ComponentProps) => {
  return (
    <Grid
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: 2,
        backgroundColor: "primary.main",
        borderRadius: 3,
        padding: 5,
        maxWidth: 500,
      }}
    >
      <Typography
        variant="h1"
        color="common.white"
        sx={{ textAlign: "center", textTransform: "capitalize" }}
      >
        {school.name}
      </Typography>
      <iframe
        style={{ border: 0, borderRadius: 5 }}
        title={school.name}
        src={school.map}
        width="100%"
        height="300"
        loading="lazy"
      ></iframe>
      <Typography
        component={"p"}
        color={common.white}
        sx={{ maxWidth: "80%", margin: "auto" }}
      >
        {school.address}
      </Typography>
      <Typography component={"p"} color={common.white}>
        {school.phone}
      </Typography>
      <Typography component={"p"} color={common.white}>
        {school.email}
      </Typography>
      <Typography component={"p"} color={common.white}>
        {school.schedule}
      </Typography>
    </Grid>
  );
};

export default React.memo(School);
