import { LinearProgress, Typography } from "@mui/material";
import Grid from "@mui/material/Grid";
import React from "react";

export type TeamMemberType = {
  image: string;
  name: string;
  position: string;
  place?: string;
};

export type ComponentProps = {
  teamMember: TeamMemberType;
};

const TeamMember = ({ teamMember }: ComponentProps) => {
  return (
    <Grid
      sx={{
        minWidth: 300,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <img
        style={{ borderRadius: "50%", maxWidth: "175px" }}
        src={teamMember.image}
        alt={teamMember.name}
      />
      <Typography
        variant="body1"
        sx={{ textTransform: "uppercase", fontWeight: 700, paddingTop: 2 }}
      >
        {teamMember.name}
      </Typography>
      <LinearProgress
        variant="determinate"
        value={100}
        sx={{
          color: "primary.main",
          width: "50%",
          marginTop: 1,
          marginBottom: 1,
        }}
      />
      <Typography variant="body2">{teamMember.position}</Typography>
      {teamMember.place && (
        <Typography variant="body2">{teamMember.place}</Typography>
      )}
    </Grid>
  );
};

export default React.memo(TeamMember);
