import React from "react";
import PageContainer from "../pageContainer";
import { Grid, LinearProgress, Typography } from "@mui/material";

const CookiePolicy = () => {
  return (
    <PageContainer>
      <Grid sx={{ padding: 5 }}>
        <Typography variant="h1" sx={{ textAlign: "" }}>
          Política de cookies
        </Typography>
        <LinearProgress variant="determinate" value={100} />
        <Typography
          variant="body1"
          sx={{ paddingTop: 2, textAlign: "justify" }}
        >
          Aquest lloc web pot utilitzar cookies (petits arxius d’informació que
          el servidor envia a l’ordinador de qui accedeix a la pàgina) per dur a
          terme determinades funcions que són considerades imprescindibles per
          al correcte funcionament i visualització del lloc. Les cookies
          utilitzades en el lloc web tenen, en tot cas, caràcter temporal amb
          l’única finalitat de fer més eficaç la seva transmissió ulterior i
          desapareixen en acabar la sessió de l’usuari. En cap cas s’utilitzaran
          les cookies per recollir informació de caràcter personal.
        </Typography>
        <Typography
          variant="body1"
          sx={{ paddingTop: 2, textAlign: "justify" }}
        >
          Seguint la política de protecció de dades de l’empresa, Autoescola
          Tramuntana informa que les cookies s’associen a l’usuari anònim i al
          seu ordinador, i no proporcionen per a sí el nom i cognoms de
          l’usuari.
        </Typography>
        <Typography
          variant="body1"
          sx={{ paddingTop: 2, textAlign: "justify" }}
        >
          L’usuari té la possibilitat de configurar el seu navegador de mode que
          se l’informi de la recepció de cookies, podent, si així ho desitja,
          impedir que siguin instal·lades en el seu disc dur. No obstant això,
          per a l’accés al website de l’Autoescola Tramuntana no serà perceptiva
          la instal·lació de cookies.
        </Typography>
      </Grid>
    </PageContainer>
  );
};

export default React.memo(CookiePolicy);
