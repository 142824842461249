import React from "react";
import Grid from "@mui/material/Grid";
import Logo from "../img/main-logo.png";
import Footer from "../sections/footer";

export type ComponentProps = {
  children: React.ReactNode;
};

const PageContainer = ({ children }: ComponentProps) => {
  return (
    <>
      <Grid
        sx={{
          display: "flex",
          flexDirection: "row",
          flexWrap: "wrap",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
          paddingTop: 10,
          paddingBottom: 10,
          backgroundColor: "#272727",
        }}
      >
        <Grid sx={{ maxWidth: "800px" }}>
          <img
            src={Logo}
            style={{ width: "100%" }}
            alt="Autoescola tramuntana logo"
          />
        </Grid>
      </Grid>
      <Grid sx={{ backgroundColor: "#fff" }}>{children}</Grid>
      <Footer />
    </>
  );
};

export default React.memo(PageContainer);
