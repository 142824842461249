import React from "react";
import PageContainer from "../pageContainer";
import { Grid, LinearProgress, Typography } from "@mui/material";
import OptionsList from "../licensePage/list/OptionsList";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";

const PrivacyPolicy = () => {
  const ownership = [
    "Identidad: Autoescola Tramuntana ",
    "NIF: B17430695 ",
    "Adreça. postal: Cl Riera Ginjolers 94, 17480 Roses, Girona ",
    "Telèfon: 972254451 ",
    "Correo electrònic: ae.tramuntanaroses@gmail.com",
  ];

  return (
    <PageContainer>
      <Grid sx={{ padding: 5 }}>
        <Typography variant="h1" sx={{ textAlign: "" }}>
          Política de privadesa
        </Typography>
        <LinearProgress variant="determinate" value={100} />
        <OptionsList
          title="Responsable"
          options={ownership}
          Icon={<ArrowForwardIosIcon />}
        />

        <Typography variant="h2" sx={{ textAlign: "left", paddingTop: 4 }}>
          Resum
        </Typography>
        <Typography
          variant="body1"
          sx={{ paddingTop: 2, textAlign: "justify" }}
        >
          En nom de l’empresa tractem la informació que ens facilita amb la
          finalitat de prestar-los el servei sol·licitat, realitzar la
          facturació del mateix. Les dades proporcionades es conservaran mentre
          es mantingui la relació comercial o durant els anys necessaris per
          complir amb les obligacions legals. Les dades no se cediran a tercers
          excepte en els casos en què existeixi una obligació legal. Vostè té
          dret a obtenir confirmació sobre si en AutoescolaTramuntana estem
          tractant les seves dades personals per tant té dret a accedir a les
          seves dades personals, rectificar les dades inexactes o sol·licitar la
          seva supressió quan les dades ja no siguin necessaris.
        </Typography>

        <Typography variant="h2" sx={{ textAlign: "left", paddingTop: 4 }}>
          Registre d’activitats del tractament
        </Typography>

        <Typography variant="h3" sx={{ textAlign: "left", paddingTop: 4 }}>
          Clients
        </Typography>

        <Typography variant="h4" sx={{ textAlign: "left", paddingTop: 4 }}>
          Finalitat del tractament
        </Typography>
        <Typography
          variant="body1"
          sx={{ paddingTop: 2, textAlign: "justify" }}
        >
          <ul>
            <li>Gestió de la relació amb els clients </li>
          </ul>
        </Typography>
        <Typography variant="h4" sx={{ textAlign: "left", paddingTop: 4 }}>
          Finalitat del tractament Descripció de les categories de clients i de
          les categories de dades personals:
        </Typography>
        <Typography
          variant="body1"
          sx={{ paddingTop: 2, textAlign: "justify" }}
        >
          <ul>
            <li>
              <strong>Clients:</strong>
              <ul>
                <li>
                  Persones amb les quals es manté una relació comercial com a
                  clients
                </li>
              </ul>
            </li>
            <li>
              <strong>Categories de dades personals:</strong>
              <ul>
                <li>
                  Els necessaris per al manteniment de la relació comercial.
                  Facturar, enviar publicitat postal o per correu electrònic,
                  servei postvenda i fidelitzaci
                </li>
                <li>
                  D&#8217;identificació: nom i cognoms, NIF, adreça postal,
                  telèfons, e-mail
                </li>
                <li>Dades bancàries: per a la domiciliació de pagaments</li>
              </ul>
            </li>
            <li>
              <strong>
                Les categories de destinataris als qui es van comunicar o
                comunicaran les dades personals:
              </strong>
              <ul>
                <li>Administració tributària</li>
              </ul>
            </li>
            <li>
              <strong>
                Quan sigui possible, els terminis previstos per a la supressió
                de les diferents categories de dades:
              </strong>
              <ul>
                <li>
                  Els previstos per la legislació fiscal respecte a la
                  prescripció de responsabilitats
                </li>
              </ul>
            </li>
          </ul>
        </Typography>
        <Typography variant="h3" sx={{ textAlign: "left", paddingTop: 4 }}>
          Potencials Clients
        </Typography>
        <Typography variant="h4" sx={{ textAlign: "left", paddingTop: 4 }}>
          Finalitat del tractament
        </Typography>
        <Typography
          variant="body1"
          sx={{ paddingTop: 2, textAlign: "justify" }}
        >
          <ul>
            <li>Gestió de la relació amb els potencials clients</li>
          </ul>
        </Typography>
        <Typography variant="h4" sx={{ textAlign: "left", paddingTop: 4 }}>
          Descripció de les categories de potencials clients i de les categories
          de dades personals:
        </Typography>
        <Typography
          variant="body1"
          sx={{ paddingTop: 2, textAlign: "justify" }}
        >
          <ul>
            <li>
              <strong>Potencials clients:</strong>
              <ul>
                <li>
                  Persones amb les quals es busca mantenir una relació comercial
                  com a clients
                </li>
              </ul>
            </li>
            <li>
              <strong>Categories de dades personals:</strong>
              <ul>
                <li>
                  Els necessaris per a la promoció comercial de l&#8217;empresa
                </li>
                <li>
                  D&#8217;identificació: nom i cognoms i adreça postal,
                  telèfons, e-mail
                </li>
              </ul>
            </li>
            <li>
              <strong>
                Les categories de destinataris als qui es van comunicar o
                comunicaran les dades personals:
              </strong>
              <ul>
                <li>No es contempla</li>
              </ul>
            </li>
            <li>
              <strong>
                Quan sigui possible, els terminis previstos per a la supressió
                de les diferents categories de dades:
              </strong>
              <ul>
                <li>Un any des del primer contacto</li>
              </ul>
            </li>
          </ul>
        </Typography>
        <Typography variant="h3" sx={{ textAlign: "left", paddingTop: 4 }}>
          Treballadors
        </Typography>
        <Typography variant="h4" sx={{ textAlign: "left", paddingTop: 4 }}>
          Finalitat del tractament
        </Typography>
        <Typography
          variant="body1"
          sx={{ paddingTop: 2, textAlign: "justify" }}
        >
          <ul>
            <li>Gestió de la relació laboral amb els emprats</li>
          </ul>
        </Typography>
        <Typography variant="h4" sx={{ textAlign: "left", paddingTop: 4 }}>
          Descripció de les categories d&#8217;empleats i de les categories de
          dades personals:
        </Typography>
        <Typography
          variant="body1"
          sx={{ paddingTop: 2, textAlign: "justify" }}
        >
          <ul>
            <li>
              <strong>Treballadors:</strong>
              <ul>
                <li>
                  Persones que treballen per al responsable del tractament
                </li>
              </ul>
            </li>
            <li>
              <strong>Categories de dades personals:</strong>
              <ul>
                <li>
                  Els necessaris per al manteniment de la relació comercial.
                  Gestionar la nòmina, formació
                </li>
                <li>
                  D&#8217;identificació: nom, cognoms, nombre de Seguretat
                  Social, adreça postal, telèfons, e-mail
                </li>
                <li>Dades acadèmiques</li>
                <li>Dades professionals</li>
                <li>
                  Dades bancàries, per a la domiciliació del pagament de les
                  nòmines
                </li>
              </ul>
            </li>
            <li>
              <strong>
                Les categories de destinataris als qui es van comunicar o
                comunicaran les dades personals:
              </strong>
              <ul>
                <li>Gestoria laboral</li>
              </ul>
            </li>
            <li>
              <strong>
                Quan sigui possible, els terminis previstos per a la supressió
                de les diferents categories de dades:
              </strong>
            </li>
            <li>
              Els previstos per la legislació fiscal i laboral respecte a la
              prescripció de responsabilitats
            </li>
          </ul>
        </Typography>
        <Typography variant="h3" sx={{ textAlign: "left", paddingTop: 4 }}>
          Proveïdors
        </Typography>
        <Typography
          variant="body1"
          sx={{ paddingTop: 2, textAlign: "justify" }}
        >
          <p>
            <strong>Finalitat del tractament</strong>
          </p>
          <ul>
            <li>Gestió de la relació amb els proveïdors</li>
          </ul>
          <p>
            <strong>
              Descripció de les categories de proveïdors i de les categories de
              dades personals:
            </strong>
          </p>
          <ul>
            <li>
              <strong>Proveïdors:</strong>
              <ul>
                <li>
                  Persones amb les quals es manté una relació comercial com a
                  proveïdors de productes i/o serveis
                </li>
              </ul>
            </li>
            <li>
              <strong>Categories de dades personals:</strong>
              <ul>
                <li>Els necessaris per al manteniment de la relació laboral</li>
                <li>
                  D&#8217;identificació: nom, NIF, adreça postal, telèfons,
                  e-mail
                </li>
                <li>Dades bancàries: per a la domiciliació de pagaments</li>
              </ul>
            </li>
            <li>
              <strong>
                Quan sigui possible, els terminis previstos per a la supressió
                de les diferents categories de dades:
              </strong>
              <ul>
                <li>
                  Els previstos per la legislació fiscal respecte a la
                  prescripció de responsabilitats
                </li>
              </ul>
            </li>
          </ul>
        </Typography>
        <Typography variant="h3" sx={{ textAlign: "left", paddingTop: 4 }}>
          VideoVigilancia
        </Typography>
        <Typography variant="h4" sx={{ textAlign: "left", paddingTop: 4 }}>
          Finalitat del tractament
        </Typography>
        <Typography
          variant="body1"
          sx={{ paddingTop: 2, textAlign: "justify" }}
        >
          <ul>
            <li>Seguretat de les persones i béns</li>
          </ul>
        </Typography>
        <Typography variant="h4" sx={{ textAlign: "left", paddingTop: 4 }}>
          Descripció de les categories d&#8217;interessats i de les categories
          de dades personals:
        </Typography>
        <Typography
          variant="body1"
          sx={{ paddingTop: 2, textAlign: "justify" }}
        >
          <ul>
            <li>
              <strong>Interessats:</strong>
              <ul>
                <li>
                  Persones que accedeixin o intentin accedir a les
                  instal·lacions
                </li>
              </ul>
            </li>
            <li>
              <strong>Categories de dades personals:</strong>
              <ul>
                <li>Imatges</li>
              </ul>
            </li>
            <li>
              <strong>
                Les categories de destinataris als qui es van comunicar o
                comunicaran les dades personals:
              </strong>
              <ul>
                <li>Administració tributària</li>
                <li>Cossos i forces de seguretat de l&#8217;estat</li>
              </ul>
            </li>
            <li>
              <strong>
                Quan sigui possible, els terminis previstos per a la supressió
                de les diferents categories de dades:
              </strong>
              <ul>
                <li>Un mes des del seu enregistrament</li>
              </ul>
            </li>
          </ul>
        </Typography>
        <Typography variant="h2" sx={{ textAlign: "left", paddingTop: 4 }}>
          ANNEX MESURES DE SEGURETAT
        </Typography>
        <Typography variant="h3" sx={{ textAlign: "left", paddingTop: 4 }}>
          INFORMACIÓ D&#8217;INTERÈS GENERAL
        </Typography>
        <Typography
          variant="body1"
          sx={{ paddingTop: 2, textAlign: "justify" }}
        >
          Aquest document ha estat dissenyat per a tractaments de dades
          personals de baix risc d&#8217;on es dedueix que el mateix no podrà
          ser utilitzat para tractaments de dades personals que incloguin dades
          personals relatives a l&#8217;origen ètnic o racial, ideologia
          política religiosa o filosòfica, filiació sindical, dades genètiques i
          biomètrics, dades de salut, i dades d&#8217;orientació sexual de les
          persones així com qualsevol altre tractament de dades que comporti alt
          risc per als drets i llibertats de les persones.
        </Typography>
        <Typography
          variant="body1"
          sx={{ paddingTop: 2, textAlign: "justify" }}
        >
          L&#8217;article 5.1.f del Reglament General de Protecció de Dades
          (RGPD) determina la necessitat d&#8217;establir garanties de seguretat
          adequades contra el tractament no autoritzat o il·lícit, contra la
          pèrdua de les dades personals, la destrucció o el dany accidental.
          Això implica l&#8217;establiment de mesures tècniques i organitzatives
          encaminades a assegurar la integritat i confidencialitat de les dades
          personals i la possibilitat (article 5.2) de demostrar que aquestes
          mesures s&#8217;han portat a la pràctica (responsabilitat proactiva).
        </Typography>
        <Typography
          variant="body1"
          sx={{ paddingTop: 2, textAlign: "justify" }}
        >
          A tenor del tipus de tractament que ha posat de manifest quan ha
          emplenat aquest formulari, les mesures mínimes de seguretat mínimes
          que hauria de tenir en compte són les següents:
        </Typography>
        <Typography variant="h3" sx={{ textAlign: "left", paddingTop: 4 }}>
          MESURES ORGANITZATIVES
        </Typography>
        <Typography variant="h4" sx={{ textAlign: "left", paddingTop: 4 }}>
          INFORMACIÓ QUE HAURÀ DE SER CONEGUDA PER TOT EL PERSONAL AMB ACCÉS A
          DADES PERSONALS
        </Typography>
        <Typography
          variant="body1"
          sx={{ paddingTop: 2, textAlign: "justify" }}
        >
          Tot el personal amb accés a les dades personals haurà de tenir
          coneixement de les seves obligacions en relació amb els tractaments de
          dades personals i seran informats sobre aquestes obligacions. La
          informació mínima que serà coneguda per tot el personal serà la
          següent:
          <ul>
            <li>
              <strong>DEURE CONFIDENCIALITAT I SECRET</strong>
              <ul>
                <li>
                  S&#8217;haurà d&#8217;evitar l&#8217;accés de persones no
                  autoritzades a les dades personals, a tal fi s&#8217;evitarà:
                  deixar les dades personals exposades a tercers (pantalles
                  electròniques desateses, documents en paper en zones
                  d&#8217;accés públic, suports amb dades personals, etc.),
                  aquesta consideració inclou les pantalles que
                  s&#8217;utilitzin per a la visualització d&#8217;imatges del
                  sistema de videovigilancia. Quan s&#8217;absent del lloc de
                  treball, es procedirà al bloqueig de la pantalla o al
                  tancament de la sessió.
                </li>
                <li>
                  Els documents en paper i suports electrònics
                  s&#8217;emmagatzemaran en lloc segur (armaris o estades
                  d&#8217;accés restringit) durant les 24 hores del dia.
                </li>
                <li>
                  No es rebutjaran documents o suports electrònics (cd, pen
                  drives, discos durs, etc.) amb dades personals sense garantir
                  la seva destrucció.
                </li>
                <li>
                  No es comunicaran dades personals o qualsevol informació
                  personal a tercers, es pararà esment especial en no divulgar
                  dades personals protegides durant les consultes telefòniques,
                  correus electrònics,tc.
                </li>
                <li>
                  El deure secret i confidencialitat persisteix fins i tot quan
                  finalitzi la relació laboral del treballador amb
                  l&#8217;empresa
                </li>
              </ul>
            </li>
            <li>
              <strong>DRETS DELS TITULARS DE LES DADES</strong>
              <ul>
                <li>
                  S&#8217;informarà a tots els treballadors sobre el procediment
                  per atendre els drets dels interessats, definint de forma
                  clara els mecanismes pels quals poden exercir-se els drets
                  (mitjans electrònics, referència al Delegat de Protecció de
                  Dades si ho hi hagués, adreça postal, etc.) tenint en compte
                  el següent:
                </li>
                <li>
                  Prèvia presentació del seu document nacional d&#8217;identitat
                  o passaport, els titulars de les dades personals (interessats)
                  podran exercir els seus drets d&#8217;accés, rectificació,
                  supressió, oposició i portabilitat. El responsable del
                  tractament haurà de donar resposta als interessats sense
                  dilació indeguda.
                </li>
                <li>
                  Per al dret d&#8217;accés es facilitarà als interessats la
                  llista de les dades personals que disposi juntament amb la
                  finalitat per la qual han estat recollits, la identitat dels
                  destinataris de les dades, els terminis de conservació, i la
                  identitat del responsable davant el qual poden sol·licitar la
                  rectificació supressió i oposició al tractament de les dades.
                </li>
                <li>
                  Per al dret de rectificació es procedirà a modificar les dades
                  dels interessats que anessin inexactes o incomplets atenent a
                  les finalitats del tractament.
                </li>
                <li>
                  Per al dret de supressió se suprimiran les dades dels
                  interessats quan els interessats manifestin la seva negativa o
                  oposició al consentiment per al tractament de les seves dades
                  i no existeixi deure legal que ho impedeixi.
                </li>
                <li>
                  Per al dret de portabilitat els interessats hauran de
                  comunicar la seva decisió i informar al responsable, si escau,
                  sobre la identitat del nou responsable al que facilitar les
                  seves dades personals.
                </li>
                <li>
                  El responsable del tractament haurà d&#8217;informar a totes
                  les persones amb accés a les dades personals sobre els termes
                  de compliment per atendre els drets dels interessats, la forma
                  i el procediment en què s&#8217;atendran aquests drets.
                </li>
              </ul>
            </li>
            <li>
              <strong>
                VIOLACIONS DE SEGURETAT DE DADES DE CARÀCTER PERSONA
              </strong>
              <ul>
                <li>
                  Quan es produeixin violacions de seguretat DE DADES DE
                  CARÀCTER PERSONAL, com per exemple, el robatori o accés
                  indegut a les dades personals es notificarà a l&#8217;Agència
                  Espanyola de Protecció de Dades en terme de 72 hores sobre
                  aquestes violacions de seguretat, incloent tota la informació
                  necessària per a l&#8217;esclariment dels fets que haguessin
                  donat lloc a l&#8217;accés indegut a les dades personals. La
                  notificació es realitzarà per mitjans electrònics a través de
                  la seu electrònica de l&#8217;Agència Espanyola de Protecció
                  de Dades en l&#8217;adreça:{" "}
                  <a href="https://sedeagpd.gob.es">https://sedeagpd.gob.es</a>
                </li>
              </ul>
            </li>
            <li>
              <strong>
                CAPTACIÓ D&#8217;IMATGES AMB CÀMERES I FINALITAT DE SEGURETAT
                (VIDEOVIGILANCIA)
              </strong>
              <ul>
                <li>
                  UBICACIÓ DE LES CÀMERES: S&#8217;evitarà la captació
                  d&#8217;imatges en zones destinades al descans dels
                  treballadors.
                </li>
                <li>
                  UBICACIÓ DE MONITORS: Els monitors on es visualitzin les
                  imatges de les càmeres se situaran en un espai d&#8217;accés
                  restringit de manera que no siguin accessibles a tercers.
                </li>
                <li>
                  CONSERVACIÓ D&#8217;IMATGES: Les imatges
                  s&#8217;emmagatzemaran durant el termini màxim d&#8217;un mes,
                  amb excepció de les imatges que siguin aportades als tribunals
                  i les forces i cossos de seguretat.
                </li>
                <li>
                  DEURE INFORMACIÓ: S&#8217;informarà sobre l&#8217;existència
                  de les càmeres i enregistrament d&#8217;imatges mitjançant un
                  distintiu informatiu on mitjançant un pictograma i un text es
                  detalli el responsable davant el qual els interessats podran
                  exercir el seu dret d&#8217;accés. En el propi pictograma es
                  podrà incloure el text informatiu. A la pàgina web de
                  l&#8217;Agència disposen de models, tant del{" "}
                  <a href="https://www.aepd.es/media/fichas/cartel-videovigilancia.pdf">
                    pictograma
                  </a>{" "}
                  com del text.
                </li>
                <li>
                  CONTROL LABORAL: Quan les càmeres vagin a ser utilitzades amb
                  la finalitat de control laboral segons el previst en
                  l&#8217;article 20.3 de l&#8217;Estatut dels Treballadors,
                  s&#8217;informarà al treballador o als seus representants
                  sobre les mesures de control establertes per l&#8217;empresari
                  amb indicació expressa de la finalitat de control laboral de
                  les imatges captades per les càmeres.
                </li>
                <li>
                  DRET D&#8217;ACCÉS A les IMATGES: Per donar compliment al dret
                  d&#8217;accés dels interessats se sol·licitarà una fotografia
                  recent i el Document Nacional d&#8217;Identitat de
                  l&#8217;interessat, així com el detall de la data i hora a la
                  qual es refereix el dret d&#8217;accés. No es facilitarà a
                  l&#8217;interessat accés directe a les imatges de les càmeres
                  en les quals es mostrin imatges de tercers. En cas de no ser
                  possible la visualització de les imatges per
                  l&#8217;interessat sense mostrar imatges de tercers, es
                  facilitarà un document a l&#8217;interessat en el qual es
                  confirmi o negui l&#8217;existència d&#8217;imatges de
                  l&#8217;interessat.
                </li>
              </ul>
            </li>
          </ul>
          <strong>
            Per a més informació pot consultar les guies de videovigilancia de
            l&#8217;Agència Espanyola de Protecció de Dades que es troben a la
            seva disposició en la secció de publicacions de la web{" "}
            <a href="http://www.aepd.es">www.aepd.es</a>.
          </strong>
        </Typography>
        <Typography variant="h3" sx={{ textAlign: "left", paddingTop: 4 }}>
          MESURES TÈCNIQUES
        </Typography>
        <Typography variant="h4" sx={{ textAlign: "left", paddingTop: 4 }}>
          IDENTIFICACIÓ
        </Typography>

        <Typography
          variant="body1"
          sx={{ paddingTop: 2, textAlign: "justify" }}
        >
          <ul>
            <li>
              Quan el mateix ordinador o dispositiu s&#8217;utilitzi per al
              tractament de dades personals i finalitats d&#8217;ús personal es
              recomana disposar de diversos perfils o usuaris diferents per a
              cadascuna de les finalitats. Han de mantenir-se separats els usos
              professional i personal de l&#8217;ordinador.
            </li>
            <li>
              Es recomana disposar de perfils amb drets d&#8217;administració
              per a la instal·lació i configuració del sistema i usuaris sense
              privilegis o drets d&#8217;administració per a l&#8217;accés a les
              dades personals. Aquesta mesura evitarà que en cas d&#8217;atac de
              ciberseguridad puguin obtenir-se privilegis d&#8217;accés o
              modificar el sistema operatiu.
            </li>
            <li>
              Es garantirà l&#8217;existència de contrasenyes per a
              l&#8217;accés a les dades personals emmagatzemades en sistemes
              electrònics. La contrasenya tindrà almenys 8 caràcters, mescla de
              nombres i lletres.
            </li>
            <li>
              Quan a les dades personals accedeixin diferents persones, per a
              cada persona amb accés a les dades personals, es disposarà
              d&#8217;un usuari i contrasenya específics (identificació
              inequívoca).
            </li>
            <li>
              S&#8217;ha de garantir la confidencialitat de les contrasenyes,
              evitant que quedin exposades a tercers. Per a la gestió de les
              contrasenyes pot consultar{" "}
              <a href="https://www.aepd.es/media/guias/guia-privacidad-y-seguridad-en-internet.pdf">
                la guia de privadesa i seguretat a internet
              </a>{" "}
              de l&#8217;Agència spanyola de Protecció de Dades i
              l&#8217;Institut Nacional de Ciberseguridad. En cap cas es
              compartiran les contrasenyes ni es deixaran anotades en lloc comú
              i l&#8217;accés de persones diferents de l&#8217;usuari.
            </li>
          </ul>
        </Typography>
        <Typography variant="h4" sx={{ textAlign: "left", paddingTop: 4 }}>
          DEURE SALVAGUARDA
        </Typography>

        <Typography
          variant="body1"
          sx={{ paddingTop: 2, textAlign: "justify" }}
        >
          <p>
            A continuació s&#8217;exposen les mesures tècniques mínimes per
            garantir la salvaguarda de les dades personals:
          </p>
          <ul>
            <li>
              ACTUALITZACIÓ D&#8217;ORDINADORS I DISPOSITIUS: Els dispositius i
              ordinadors utilitzats per a l&#8217;emmagatzematge i el tractament
              de les dades personals hauran de mantenir-se actualitzats en la
              mitjana possible.
            </li>
            <li>
              MALWARE: En els ordinadors i dispositius on es realitzi el
              tractament automatitzat de les dades personals es disposarà
              d&#8217;un sistema d&#8217;antivirus que garanteixi en la mesura
              possible el robatori i destrucció de la informació i dades
              personals. El sistema d&#8217;antivirus haurà de ser actualitzat
              de forma periòdica.
            </li>
            <li>
              TALLAFOCS O FIREWALL: Per evitar accessos remots indeguts a les
              dades personals es vetllarà per garantir l&#8217;existència
              d&#8217;un firewallactivat en aquells ordinadors i dispositius en
              els quals es realitzi l&#8217;emmagatzematge i/o tractament de
              dades personals.
            </li>
            <li>
              XIFRAT DE DADES: Quan es precisi realitzar l&#8217;extracció de
              dades personals fos del recinte on es realitza el seu tractament,
              ja sigui per mitjans físics o per mitjans electrònics,
              s&#8217;haurà de valorar la possibilitat d&#8217;utilitzar un
              mètode d&#8217;encriptació per garantir la confidencialitat de les
              dades personals en cas d&#8217;accés indegut a la informació.
            </li>
            <li>
              CÒPIA DE SEGURETAT: Periòdicament es realitzarà una còpia de
              seguretat en un segon suport diferent del que s&#8217;utilitza per
              al treball diari. La còpia s&#8217;emmagatzemarà en lloc segur,
              diferent d&#8217;aquell en què estigui situat l&#8217;ordinador
              amb els fitxers originals, amb la finalitat de permetre la
              recuperació de les dades personals en cas de pèrdua de la
              informació.
            </li>
          </ul>
          <p>
            Les mesures de seguretat seran revisades de forma periòdica, la
            revisió podrà realitzar-se per mecanismes automàtics (programari o
            programes informàtics) o de forma manual. Consideri que qualsevol
            incident de seguretat informàtica que li hagi ocorregut a qualsevol
            conegut li pot ocórrer a vostè, i previngui&#8217;s contra el
            mateix.
          </p>
          <p>
            Si desitja més informació o orientacions tècniques per garantir la
            seguretat de les dades personals i la informació que tracta la seva
            empresa, l&#8217;Institut Nacional de Ciberseguridad(INCIBE) a la
            seva pàgina web <a href="http://www.incibe.es">www.incibe.és</a>,
            posa a la seva disposició eines amb enfocament empresarial en la
            seva secció «Protegeix la teva empresa» on, entre altres serveis,
            disposa de:
          </p>
          <ul>
            <li>
              Un apartat de{" "}
              <a href="https://www.incibe.es/protege-tu-empresa/formacion">
                formació
              </a>{" "}
              amb un{" "}
              <a href="https://www.incibe.es/protege-tu-empresa/hackend">
                videojoc
              </a>
              ,{" "}
              <a href="https://www.incibe.es/protege-tu-empresa/juego-rol-pyme-seguridad">
                reptes
              </a>{" "}
              per a resposta a incidents i videosinteractius de
              <a href="https://itinerarios.incibe.es/"> formació sectorial.</a>
            </li>
            <li>
              Un{" "}
              <a href="https://www.incibe.es/protege-tu-empresa/kit-concienciacion">
                Kit de conscienciació
              </a>{" "}
              per a empleats.
            </li>
            <li>
              Diverses{" "}
              <a href="https://www.incibe.es/protege-tu-empresa/herramientas">
                eines
              </a>{" "}
              per ajudar a l&#8217;empresa a millorar la seva ciberseguridad,
              entre elles{" "}
              <a href="https://www.incibe.es/protege-tu-empresa/herramientas/politicas">
                polítiques
              </a>{" "}
              per a l&#8217;empresari, el personal tècnic i l&#8217;empleat, un{" "}
              <a href="https://www.incibe.es/protege-tu-empresa/catalogo-de-ciberseguridad/buscador-soluciones?term_node_tid_depth_join=339&amp;combine=&amp;term_node_tid_depth_join_1=All&amp;field_sol_dimension_tid=All&amp;field_sol_gratuito_value=1&amp;submit=Buscar">
                catàleg
              </a>{" "}
              d&#8217;empreses i solucions de seguretat i una{" "}
              <a href="https://www.incibe.es/protege-tu-empresa/conoces-tus-riesgos">
                eina &#8216;anàlisi de riscos.
              </a>
            </li>
            <li>
              <a href="https://www.incibe.es/protege-tu-empresa/que-te-interesa">
                Dosieres temàtics
              </a>{" "}
              complementats amb videosi infografies i altres recursos.
            </li>
            <li>
              <a href="https://www.incibe.es/protege-tu-empresa/guias">Guies</a>{" "}
              per a l&#8217;empresari,
            </li>
          </ul>
          <p>
            A més INCIBE, a través de l
            <a href="http://www.osi.es">
              &#8216;Oficina de Seguretat de l&#8217;Internauta
            </a>
            , posa també a la seva disposició{" "}
            <a href="https://www.osi.es/es/herramientas">eines</a> informàtiques
            gratuïtes i informació addicional poden ser d&#8217;utilitat per a
            la seva empresa o la seva activitat professional.
          </p>
        </Typography>
      </Grid>
    </PageContainer>
  );
};

export default React.memo(PrivacyPolicy);
