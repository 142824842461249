import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import Theme from "./theme/Theme";
import LicensePage from "./licensePage/LicensePage";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { ThemeProvider } from "@emotion/react";
import NotFoundPage from "./notFoundPage";
import LegalAdvicePage from "./legalAdvicePage";
import ContractConditions from "./contractConditions";
import CookiePolicy from "./cookiePolicy";
import PrivacyPolicy from "./privacyPolicy";

const router = createBrowserRouter([
  {
    path: "/",
    element: (
      <div
        className="App"
        style={{
          background:
            'linear-gradient(rgba(0,0,0,.5), rgba(0,0,0,.5)), url("img/background-banner.png")',
          backgroundSize: "cover",
          backgroundPosition: "top center",
          backgroundRepeat: "no-repeat",
          height: "100vh",
          width: "100vw",
          overflowX: "hidden",
        }}
      >
        <App />
      </div>
    ),
  },
  {
    path: "/carnet/:license",
    element: <LicensePage />,
  },

  {
    path: "/avis-legal",
    element: <LegalAdvicePage />,
  },
  {
    path: "/condicions-generals-de-contractacio",
    element: <ContractConditions />,
  },
  {
    path: "/politica-de-cookies",
    element: <CookiePolicy />,
  },
  {
    path: "/politica-de-privadesa-i-proteccio-de-dades",
    element: <PrivacyPolicy />,
  },
  {
    path: "*",
    element: <NotFoundPage />,
  },
]);

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <ThemeProvider theme={Theme}>
      <RouterProvider router={router} />
    </ThemeProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
