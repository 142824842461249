import React from "react";
import Logo from "../../img/main-logo.png";
import Grid from "@mui/material/Grid";
import { Button } from "@mui/material";

const Header = () => {
  const buttons = React.useMemo(() => {
    return (
      <Grid
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          maxWidth: "80%",
          flexWrap: "wrap",
          gap: 2,
        }}
      >
        <Button
          variant="contained"
          color="primary"
          onClick={() =>
            window.open(
              "https://sedeclave.dgt.gob.es/WEB_NOTP_CONSULTA/consultaNota.faces",
              "_blank"
            )
          }
          sx={{ padding: 1.5, paddingLeft: 4, paddingRight: 4, minWidth: 250 }}
        >
          RESULTATS D'EXAMENS
        </Button>

        <Button
          variant="contained"
          color="secondary"
          onClick={() =>
            window.open("https://www.matferline.com/acceso/index.php", "_blank")
          }
          sx={{ padding: 1.5, paddingLeft: 4, paddingRight: 4, minWidth: 250 }}
        >
          MATFERLINE TESTS
        </Button>
      </Grid>
    );
  }, []);

  return (
    <Grid
      id="mainLogoSection"
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        paddingLeft: 1,
        paddingRight: 1,
        width: "100%",
        paddingTop: 30,
        paddingBottom: 25,
      }}
    >
      <img
        src={Logo}
        alt="Logo"
        style={{ marginBottom: 10, maxWidth: "80%", zIndex: 1 }}
      />

      {buttons}
    </Grid>
  );
};

export default React.memo(Header);
