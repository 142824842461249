import CircularProgress from "@mui/material/CircularProgress";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { common } from "@mui/material/colors";
import React from "react";

export type AptitudeType = {
  label: string;
  color?: string;
};

export type ComponentProps = {
  aptitude: AptitudeType;
};
const Aptitude = ({ aptitude }: ComponentProps) => {
  const aptitudeColor = React.useMemo(() => {
    if (!aptitude.color) return "primary";
    return aptitude.color;
  }, [aptitude.color]);

  return (
    <Grid
      sx={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <CircularProgress
        variant="determinate"
        value={100}
        size={60}
        sx={{
          color: aptitudeColor,
        }}
      />
      <Typography
        variant="body2"
        sx={{
          color: common.white,
          position: "relative",
          left: -50,
          fontSize: 16,
          fontWeight: 700,
        }}
      >
        100%
      </Typography>
      <Typography
        variant="body2"
        sx={{
          color: common.white,
          fontSize: "16px",
          lineHeight: "1.5",
          textTransform: "uppercase",
          fontWeight: 700,
        }}
      >
        {aptitude.label}
      </Typography>
    </Grid>
  );
};

export default React.memo(Aptitude);
