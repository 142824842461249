import { Grid, Typography } from "@mui/material";
import React from "react";
import License from "./license/License";
import { licenses } from "../../data";

const Licenses = () => {
  return (
    <Grid
      sx={{
        display: "flex",
        alignContent: "center",
        flexDirection: "column",
        backgroundColor: "white",
        paddingBottom: 6,
      }}
    >
      <Grid sx={{ marginTop: 9, marginBottom: 5 }}>
        <Typography variant="h1" sx={{ textAlign: "center" }}>
          LLICÈNCIES
        </Typography>
        <Typography component="p" sx={{ textAlign: "center" }}>
          Explora tot el que som capaços d'ensenyar-te!
        </Typography>
      </Grid>
      <Grid
        sx={{
          display: "flex",
          flexWrap: "wrap",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "center",
          gap: 2,
        }}
      >
        {licenses.map((license, index) => (
          <License key={`license-${index}`} license={license} />
        ))}
      </Grid>
    </Grid>
  );
};

export default React.memo(Licenses);
