import Header from "./sections/header";
import Licenses from "./sections/licenses";
import WhoWeAre from "./sections/whoWeAre";
import Team from "./sections/team";
import Schools from "./sections/schools";
import Extra from "./sections/extra";
import Footer from "./sections/footer";

function App() {
  return (
    <>
      <Header />
      <Licenses />
      <WhoWeAre />
      <Team />
      <Schools />
      <Extra />
      <Footer />
    </>
  );
}

export default App;
