import { Typography } from "@mui/material";
import Grid from "@mui/material/Grid";
import { common } from "@mui/material/colors";
import TeamMember, { TeamMemberType } from "./teamMember/TeamMember";
import React from "react";

import KetyMotasImg from "../../img/kety_motas.png";
import PereBagueImg from "../../img/pere_bague.png";
import ManuMolinaImg from "../../img/manu_molina.png";
import IsabelVilaImg from "../../img/isabel_vila.png";
import ManoloGonzalezImg from "../../img/manolo_gonzalez.png";
import CarmenCarmonaImg from "../../img/carmen_carmona.png";
import ManuRubioImg from "../../img/manu_rubio.png";
import LiaSilvaImg from "../../img/lia_silva.png";
import LaiaGelaberto from "../../img/laia_gelaberto.png";
import ElenaVilaImg from "../../img/elena_vila.png";
import MayFernandezImg from "../../img/may_fernandez.png";
import SoniaRiusImg from "../../img/sonia_rius.png";
import NouraElAhrachImg from "../../img/noura_el_ahrach.png";

const teamMembers: TeamMemberType[] = [
  {
    image: KetyMotasImg,
    name: "Kety motas",
    position: "Co-Fundadora i Professora",
  },
  {
    image: PereBagueImg,
    name: "Pere Bagué",
    position: "Co-Fundador i Professor",
  },
  {
    image: ManuMolinaImg,
    name: "Manu Molina",
    position: "Professor",
  },
  {
    image: IsabelVilaImg,
    name: "Isabel Vilà",
    position: "Professora",
  },
  {
    image: ManoloGonzalezImg,
    name: "Manolo González",
    position: "Professor",
  },
  {
    image: CarmenCarmonaImg,
    name: "Carmen Carmona",
    position: "Professora",
  },
  {
    image: ManuRubioImg,
    name: "Manu Rubio",
    position: "Professor",
  },
  {
    image: LiaSilvaImg,
    name: "Lia Silva",
    position: "Professora",
  },
  {
    image: ElenaVilaImg,
    name: "Elena Vilà",
    position: "Atenció al públic",
    place: "Castelló d’empúries",
  },
  {
    image: MayFernandezImg,
    name: "May Fernández",
    position: "Atenció al públic",
    place: "Roses",
  },
  {
    image: LaiaGelaberto,
    name: "Laia Gelabertó",
    position: "Atenció al públic",
    place: "Roses",
  },
  {
    image: SoniaRiusImg,
    name: "Sònia Rius",
    position: "Atenció al públic",
    place: "Empuriabrava",
  },
  {
    image: NouraElAhrachImg,
    name: "Noura El Ahrach",
    position: "Atenció al públic",
    place: "Castelló d’empúries",
  },
];

const Team = () => {
  return (
    <Grid
      sx={{
        backgroundColor: common.white,
        textAlign: "center",
        paddingTop: 10,
      }}
    >
      <Typography variant="h1" sx={{ paddingBottom: 3 }}>
        El nostre equip
      </Typography>
      <Grid
        sx={{
          display: "flex",
          flexDirection: "row",
          flexWrap: "wrap",
          alignItems: "center",
          justifyContent: "center",
          gap: 4,
        }}
      >
        {teamMembers.map((teamMember, index) => (
          <TeamMember key={`teamMember-${index}`} teamMember={teamMember} />
        ))}
      </Grid>
    </Grid>
  );
};

export default React.memo(Team);
