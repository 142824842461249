import React from "react";
import PageContainer from "../pageContainer";
import { Grid, LinearProgress, Typography } from "@mui/material";
import OptionsList from "../licensePage/list/OptionsList";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";

const ContractConditions = () => {
  const generalConditions = [
    "La duració de cada classe teòrica serà de 45 minuts i la de cada classe pràctica de com a mínim 45 minuts.",
    "En cas de dos NO APTES consecutius caldrà abonar la meitat del curs teòric intensiu (92,50 €)",
    "Per poder ser presentat a l’examen serà necessària una certificació conjunta del director i del professorat que hagi impartit les ensenyances de la conducció en la qual s’expressi que, al seu judici, l’alumne ha rebut les ensenyances necessàries per poder obtenir el permís de conducció. Si l’alumne insistís a ser presentat a examen sense aquest requisit, el director de l’escola ho farà constar expressament, resolent en cada cas la “Direcció Provincial de Trànsit”, segons procedeixi.",
    "Les despeses d’inscripció en l’escola s’abonaran una sola vegada, independentment del nombre de convocatòries necessàries per a l’obtenció del permís.",
    "L’alumne serà acompanyat durant la prova de circulació pel professor que li hagi impartit l’ensenyança pràctica i qui serà responsabilitzat del doble comandament, excepte casos degudament justificats i autoritzats per la “Direcció Provincial de Trànsit”.",
    "L’escola vetllarà pel compliment de tot el que es disposa en el vigent contracte, així com de l’estricte compliment de les obligacions del professorat, atenent qualsevol reclamació o queixa de l’alumne en relació amb l’ensenyança rebuda. En cas que l’alumne quedi en disconformitat disposa d’un llibre de reclamacions en el que podrà manifestar les seves queixes per a coneixement del servei català de trànsit.",
    "La declaració de d’ “Aptitud” a la prova tindrà un període de vigència de 2 anys. Si entre convocatòria i convocatòria d’un mateix expedient NO APTE passen més de 6 mesos l’expedient expira (caduca).",
    "L’alumne ha de superar les proves d’aptitud mèdiques i psicològiques legalment exigides per a l’obtenció del permís sol·licitat. Si l’alumne no les supera, no podrà reclamar cap responsabilitat a l’autoescola tramuntana.",
  ];

  const particularConditions = [
    "Si transcorregut 1 any de la data del present contracte l’alumne no ha obtingut el permís de conducció per causa aliena al centre de formació viària, aquest rescindirà el present contracte renunciant l’alumne expressament a qualsevol quantitat entregada en concepte de “entregat”, però no a les tasses oficials, sempre que aquestes no haguessin estat entregades en el seu nom a l’organisme corresponent. Per cap concepte els imports abonats per un alumne seran retornats ni transferits a un altre.",
    "Si l’alumne renunciés a l’obtenció del permís de conducció desitjat, no per això deixaria d’abonar l’import dels serveis que a la liquidació del present contracte haguessin sigut prestats per l’autoescola, d’acord amb els preus ressenyats.",
    "L’alumne abonarà al centre de formació viària anticipadament els imports pels serveis a rebre. El pagament de les classes pràctiques s’efectuarà també per avançat, mitjançant la compra prèvia de tiquets de pràctiques.",
    "La documentació personal l’haurà de portar l’alumne amb 15 dies d’antelació.",
    "Si l’alumne no pogués assistir a realitzar la classe pràctica per qualsevol circumstància, haurà de comunicar-ho amb 24 hores d’antelació, en cas contrari es veurà obligat a abonar la mateixa. Imprescindible presentar els tiquets de les pràctiques al professor.",
    "Si durant el període d’ensenyament s’observa una actitud negligent o dolosa de l’alumne, que ha causat danys a les instal·lacions, serveis, vehicles o al bon nom de l’escola, o es preveu per la seva actitud que pugui causar els danys, l’autoescola podrà suspendre cautelarment aquest contracte i sotmetre’l a la junta arbitral de consum de la Generalitat de Catalunya. Si es dicta un laude per decidir la rescissió d’aquest contracte, l’autoescola tornarà l’import corresponent a les taxes administratives, si no s’haguessin pagat a la “Direcció Provincial de Trànsit”, i les de les pràctiques i exàmens no realitzats.",
    "Independentment del que es disposa a la condició general tercera, l’alumne podrà ser presentat a examen sempre que ho permetin les disponibilitats de places d’examen.",
    "Per a la decisió de totes les qüestions litigioses derivades d’aquest contracte, les parts se sotmeten a l’arbitratge institucional de la junta arbitral de consum de la Generalitat de Catalunya a la que s’encomana la designació d’àrbitres i l’administració de l’arbitratge, i s’obliguen a complir la decisió arbitral.",
    "Aquest contracte queda realitzat d’acord amb la normativa de trànsit vigent en el moment de firmar-se. Si la normativa varia durant la vigència del contracte, les parts acorden que el contracte s’ajustarà a les noves disposicions de caràcter legal o reglamentari que siguin aplicades.",
    "La validesa del contracte referent als preus serà de sis mesos a partir de la firma.",
    "Si es dóna el cas que l’alumne no supera la prova de circulació haurà de realitzar un mínim de classes pràctiques necessàries que estimi oportú el seu professor de formació viària per poder optar a un nou examen. Si no és així el present contracte podrà ser rescindit.",
    "Una vegada obert l’expedient de l’examen, té una validesa de 6 mesos comptats a partir de l’últim examen NO APTE.",
    "L’escola estableix el sistema de pagaments.",
  ];

  return (
    <PageContainer>
      <Grid sx={{ padding: 5 }}>
        <Typography variant="h1" sx={{ textAlign: "" }}>
          Condicions generals de contractació
        </Typography>
        <LinearProgress variant="determinate" value={100} />

        <Typography variant="h2" sx={{ textAlign: "left", paddingTop: 4 }}>
          Identificació del document.
        </Typography>
        <Typography
          variant="body1"
          sx={{ paddingTop: 2, textAlign: "justify" }}
        >
          El present document constitueix un conjunt de condicions generals que
          regeixen en la contractaació de serveis proporcionats per Autoescola
          Tramuntana a un usuari a través de qualsevol mitjà de comunicació
          possible. Aquestes condicions seran d’obligat compliment per a
          l’usuari i el centre.
        </Typography>

        <Typography variant="h2" sx={{ textAlign: "left", paddingTop: 4 }}>
          Objecte del servei
        </Typography>
        <Typography
          variant="body1"
          sx={{ paddingTop: 2, textAlign: "justify" }}
        >
          L’objecte del servei recollit en aquestes condicions generals de
          contractació és la impartició de les ensenyances pera a l’obtenció del
          permís de conducció resenyat, amb la màxima dediació i eficàcia, i amb
          professorat que compleix tots els requisits exigits en el reglament
          d’Escoles Particulars de Conductors aprovat pel reaial decret
          1295/2003 amb data 17 d’Octubre.{" "}
        </Typography>

        <Typography variant="h2" sx={{ textAlign: "left", paddingTop: 4 }}>
          Les parts de la prestació del servei
        </Typography>
        <Typography
          variant="body1"
          sx={{ paddingTop: 2, textAlign: "justify" }}
        >
          Els titulars del contracte de prestació dels serveis seran
          exclusivament l’alumne en cas que sigui major d’edat o bé el tutor
          legal de l’interessat si és menor d’edat per una part i l’autoescola
          Tramuntana de l’altre part.{" "}
        </Typography>
        <Typography
          variant="body1"
          sx={{ paddingTop: 2, textAlign: "justify" }}
        >
          L’usuari té el dret de rebre una còpia d’aquestes condicions generals
          de contractació per escrit i imprés quan ho sol·liciti.{" "}
        </Typography>

        <Typography variant="h2" sx={{ textAlign: "left", paddingTop: 4 }}>
          Què cal fer per formalitzar un contracte
        </Typography>
        <Typography
          variant="body1"
          sx={{ paddingTop: 2, textAlign: "justify" }}
        >
          Per realitzar un contracte amb L’Autoescola Tramuntana cal
          matricular-se per a l’obtenció de qualsevol de les llicències de
          conducció ofertades o en qualsevol de les activitats o cursos que es
          proporcionin des de l’Autoescola Tramuntana.
        </Typography>
        <Typography
          variant="body1"
          sx={{ paddingTop: 2, textAlign: "justify" }}
        >
          Les condicions generals de contractació queden publicades en aquesta
          mateixa pàgina web i són accessibles per qualsevol persona que ho
          requereixi.{" "}
        </Typography>

        <Typography variant="h2" sx={{ textAlign: "left", paddingTop: 4 }}>
          Correcció de dades errònies.
        </Typography>
        <Typography
          variant="body1"
          sx={{ paddingTop: 2, textAlign: "justify" }}
        >
          En cas que un alumne o un client detecti que les dades introduïdes en
          el nostre sistema son errònies o vol exercir qualsevol dels drets de
          modificació o cancel·lació de les mateixes ho pot realitzar escrivint
          un correu electrònic a ae.tramuntanaroses@gmail.com.
        </Typography>

        <Typography variant="h2" sx={{ textAlign: "left", paddingTop: 4 }}>
          Idiomes
        </Typography>
        <Typography
          variant="body1"
          sx={{ paddingTop: 2, textAlign: "justify" }}
        >
          Aquest mateix contracte és del tot inclusiu amb els idiomes i es pot
          realitzar mitjançant qualsevol de les llengües parlades pels nostres
          professionals de salut, aquestes llengües són el català i el castellà.
          Per poder aconseguir aquest contracte amb un d’aquests idiomes
          esmentats que no sigui el català caldrà avisar amb 3 dies d’antelació
          per tal que el centre tingui el temps necessari per proporcionar-li
          una còpia del mateix amb l’idioma desitjat i que sigui la versió més
          recent i actualitzada.{" "}
        </Typography>

        <OptionsList
          title="Condicions Generals"
          options={generalConditions}
          Icon={<ArrowForwardIosIcon />}
        />

        <OptionsList
          title="Condicions Particulars"
          options={particularConditions}
          Icon={<ArrowForwardIosIcon />}
        />
      </Grid>
    </PageContainer>
  );
};

export default React.memo(ContractConditions);
