import React from "react";
import Grid from "@mui/material/Grid";
import { Typography } from "@mui/material";
import Aptitude from "./aptitude/Aptitude";
import { common } from "@mui/material/colors";

const WhoWeAre = () => {
  const aptitudes = React.useMemo(() => {
    return (
      <Grid
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Grid
          sx={{
            display: "flex",
            maxWidth: "500",
            gap: 2,
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "flex-start",
          }}
        >
          <Aptitude aptitude={{ label: "FIABILITAT" }} />
          <Aptitude
            aptitude={{ label: "PROFESSIONALITAT", color: "secondary.main" }}
          />
          <Aptitude aptitude={{ label: "EFICIÈNCIA", color: "#3ab0e2" }} />
        </Grid>
      </Grid>
    );
  }, []);

  return (
    <Grid
      sx={{
        backgroundColor: "#272727",
        paddingLeft: 3,
        paddingTop: 8,
        paddingBottom: 5,
        paddingRight: 3,
      }}
    >
      <Typography
        variant="h1"
        color={common.white}
        sx={{ textAlign: "center", paddingBottom: 3 }}
      >
        Qui Som?
      </Typography>
      <Typography
        variant="h2"
        color={common.white}
        sx={{ textAlign: "center", paddingBottom: 4 }}
      >
        Som una autoescola de proximitat
      </Typography>
      <Typography variant="body2" sx={{ paddingBottom: 3 }}>
        Localitzada a la comarca de l'Alt Empordà des de fa més de trenta anys.
        Hem aconseguit que hagin passat pels nostres centres més de 10.000
        alumnes (3 generacions).{" "}
      </Typography>
      <Typography variant="body2" sx={{ paddingBottom: 3 }}>
        Disposem de personal experimentat i titulat en l'àmbit de la seguretat
        viària. Proporcionem un ensenyament individualitzat als nostres alumnes
        tant en la part teòrica com en la part pràctica.
      </Typography>
      {aptitudes}
    </Grid>
  );
};

export default React.memo(WhoWeAre);
