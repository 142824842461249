import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { common } from "@mui/material/colors";
import React from "react";
const Extra = () => {
  return (
    <Grid
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "primary.main",
        paddingLeft: 5,
        paddingTop: 8,
        paddingBottom: 5,
        paddingRight: 5,
      }}
    >
      <Typography variant={"h2"} color={common.white}>
        Comprova el resultat de l'examen!!
      </Typography>

      <Button
        variant="contained"
        color="secondary"
        sx={{
          marginTop: 3,
          marginBottom: 3,
          padding: 1.5,
          paddingLeft: 4,
          paddingRight: 4,
          minWidth: 250,
        }}
        onClick={() =>
          window.open(
            "https://sedeclave.dgt.gob.es/WEB_NOTP_CONSULTA/consultaNota.faces",
            "_blank"
          )
        }
      >
        Resultats d'examens
      </Button>
    </Grid>
  );
};

export default React.memo(Extra);
