import { LicenseType } from "./sections/licenses/license/License";
import AImg from "./img/moto_a.png";
import A1Img from "./img/moto_a1.png";
import A2Img from "./img/moto_A2.png";
import AMImg from "./img/moto_am.png";
import BImg from "./img/Cotxe_b.png";
import B96Img from "./img/caravanaS.png";
import CImg from "./img/camio_c.png";
import CEImg from "./img/ce.png";
import C1Img from "./img/camio_c1.png";
import DImg from "./img/bus_D.png";
import D1Img from "./img/Bus_D1.png";

export const licenses: LicenseType[] = [
  {
    name: "A",
    logo: AImg,
    description:
      "Vehicles de AM, A1 i A2; motocicletes de més de 35kw, tricicles de motor sense límit.",
    requirements: [
      "A partir dels 21 anys",
      "Curs de 3 hores de teòrica.",
      "4 hores de pràctiques de pistes.",
      "2 hores de pràctiques de circulació.",
      "És necessari estar en possessió del permís A2 amb una antiguitat de 2 anys.",
    ],
    exams: ["No és necessari examen DGT."],
  },
  {
    name: "A1",
    logo: A1Img,
    description:
      "Vehicles de AM, motocicletes amb o sense sidecar fins a 125cm3; i tricicles de motor fins a 15kW. (La teòrica es valida pel permís B i A2).",
    requirements: ["Permís per a majors de 16 anys."],
    exams: [
      "Examen teòric específic de 20 preguntes.",
      "Examen teòric comú de 30 preguntes.",
      "Examen de circuit tancat.",
      "Examen de circulació.",
    ],
  },
  {
    name: "A2",
    logo: A2Img,
    description:
      "Vehicles de AM i A1; i motocicletes amb o sense sidecar fins a  35kW.",
    requirements: ["Permís per a majors de 18 anys"],
    exams: [
      "Teòric específic de 20 preguntes.",
      "Teòric comú de 30 preguntes.",
      "Circuit tancat.",
      "Circulació.",
      "Si  disposa de A1 amb 2 anys d’antiguitat: només circulació.",
    ],
  },
  {
    name: "AM",
    logo: AMImg,
    description:
      "Ciclomotors de dos i tres rodes; i quadricicles lleugers. (La teòrica no convalida cap altre permís).",
    requirements: ["Permís per a majors de 15 anys."],
    exams: [
      "Exàmen teòric específic de 20 preguntes tipo test amb 20 minuts i màxim de dos errors.",
    ],
  },
  {
    name: "B",
    logo: BImg,
    description:
      "Automòbils amb una massa màxima de 3.500kg més remolc de fins a 750 kg. A partir dels 3 anys autoritza a conduir motocicletes fins a 125cm3.",
    requirements: ["Permís per a majors de 18 anys."],
    exams: [
      "Examen teòric comú de 30 preguntes.",
      "Examen pràctic de circulació.",
      "Si disposa de A1 o A2: examen de circulació.",
    ],
  },
  {
    name: "B96",
    logo: B96Img,
    description: "Remolc de més de 750kg amb un conjunt màxim de 4.250kg.",
    requirements: ["És necessari disposar del permís B."],
    exams: ["Pràctic de pistes", "Pràctic de circulació"],
  },
  {
    name: "C",
    logo: CImg,
    description:
      "Camions i vehicles especials no agrícoles de més de 3.500kg, fins a 9 places (inclòs el conductor).",
    requirements: ["Major de 21 anys.", "Disposar del permís B."],
    exams: [
      "Teòric específic de 20 preguntes.",
      "Pràctic de pistes.",
      "Pràctic de circulació.",
      "Si disposa del C1: Pistes i pràctic de circulació.",
    ],
  },
  {
    name: "C+E",
    logo: CEImg,
    description:
      "Autoritza la conducció de camions de més 3.500 kg i remolcs o semiremolcs pesats.",
    requirements: ["Major de 21 anys.", "Disposar del permís B."],
    exams: [
      "Teòric específic de 20 preguntes.",
      "Pràctic de pistes.",
      "Pràctic de circulació.",
    ],
  },
  {
    name: "C1",
    logo: C1Img,
    description:
      "Camions amb un pes màxim de 7.500kg, fins a 9 places (inclòs el conductor).",
    requirements: [
      "Majors de 18 anys.",
      "És necessari estar en possessió del permís B.",
    ],
    exams: [
      "Teòric específic de 20 preguntes.",
      "Pràctic de pistes.",
      "Pràctic de circulació.",
    ],
  },
  {
    name: "D",
    logo: DImg,
    description:
      "Automòbils destinats al transport de persones, a partir de 10 places.",
    requirements: ["Majors de 24 anys.", "Disposar del permís B."],
    exams: [
      "Teòric específic de 20 preguntes.",
      "Pràctic de pistes.",
      "Pràctic de circulació.",
    ],
  },
  {
    name: "D1",
    logo: D1Img,
    description:
      "Automòbils destinats al transport de persones, a partir de 10 places i fins un màxim de 17.",
    requirements: ["Majors de 21 anys.", "Tenir en possessió el permís B."],
    exams: [
      "Teòric específic de 20 preguntes.",
      "Pràctic de pistes.",
      "Pràctic de circulació.",
    ],
  },
];
