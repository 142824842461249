import Grid from "@mui/material/Grid";
import React from "react";
import NotFoundLogo from "../img/notFound.png";
import { Button, Typography } from "@mui/material";
import Footer from "../sections/footer";
import { useNavigate } from "react-router-dom";
import PageContainer from "../pageContainer/PageContainer";

const NotFoundPage = () => {
  const navigate = useNavigate();
  return (
    <PageContainer>
      <>
        <Grid
          sx={{
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
            justifyContent: "center",
            padding: 5,
            paddingTop: 10,
          }}
        >
          <Typography variant="h1" sx={{ textAlign: "center" }}>
            Sembla que no s'ha trobat el que busques...
          </Typography>
          <img
            src={NotFoundLogo}
            style={{ width: "50%" }}
            alt="Not found logo"
          />
          <Button
            sx={{ marginTop: 5 }}
            variant="contained"
            color="primary"
            onClick={() => navigate("/")}
          >
            PÀGINA PRINCIPAL
          </Button>
        </Grid>
        <Footer />
      </>
    </PageContainer>
  );
};
export default React.memo(NotFoundPage);
