import { Typography } from "@mui/material";
import Grid from "@mui/material/Grid";
import { common } from "@mui/material/colors";
import React from "react";
import School, { SchoolType } from "./school/School";

const schools: SchoolType[] = [
  {
    name: "Roses",
    map: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2952.680706770506!2d3.177582876700353!3d42.26399067120202!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x12ba66acf94c8729%3A0x918a6d188b2218bc!2sAutoescuela%20Tramuntana!5e0!3m2!1ses!2ses!4v1706969749044!5m2!1ses!2ses",
    address: "C/ Riera Ginjolers nº94-96 Roses, 17480, Girona",
    email: "ae.tramuntanaroses@gmail.com",
    schedule:
      "Obert de Dilluns a divendres, de 10:00 a 13:00 i de 16:00 a 20:00",
    phone: "+34 972 254 451",
  },
  {
    name: "Empuriabrava",
    map: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d94509.46756531404!2d2.974792043359367!3d42.24818420000001!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x12ba61bf4ca7bce9%3A0x957d88cda4810026!2sAutoescola%20tramuntana!5e0!3m2!1ses!2ses!4v1706969785761!5m2!1ses!2ses",
    address: "C/ Port Empordà nº48 Empuriabrava, 17487, Girona",
    email: "ae.tramuntanaroses@gmail.com",
    schedule:
      "Obert de Dilluns a divendres, de 10:00 a 13:00 i de 16:00 a 19:00",
    phone: "+34 972 450 223",
  },
  {
    name: "Castelló d’Empúries",
    map: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2953.074157877564!2d3.073120676699988!3d42.25558887120283!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x12ba8a3fb96a885f%3A0x58ca10740a7d358a!2sAutoescuela%20Tramuntana!5e0!3m2!1ses!2ses!4v1706969818550!5m2!1ses!2ses",
    address: "C/ Santa Clara nº12 Castellò d’Empuries, 17486, Girona",
    email: "ae.tramuntanaroses@gmail.com",
    schedule:
      "Obert de Dilluns a divendres, de 10:00 a 13:00 i de 16:00 a 20:00",
    phone: "+34 972 156 007",
  },
];

const Schools = () => {
  return (
    <Grid
      sx={{
        backgroundColor: "#272727",
        paddingLeft: 3,
        paddingTop: 8,
        paddingBottom: 5,
        paddingRight: 3,
      }}
    >
      <Typography
        variant="h1"
        color={common.white}
        sx={{ textAlign: "center" }}
      >
        Autoescoles
      </Typography>
      <Typography component={"p"}>
        Vine a veure'ns en alguna de les tres autoescoles que tenim!
      </Typography>
      <Grid
        sx={{
          display: "flex",
          flexDirection: "row",
          flexWrap: "wrap",
          gap: 2,
          alignItems: "center",
          justifyContent: "center",
          paddingTop: 5,
        }}
      >
        {schools.map((school, index) => (
          <School key={`school-${index}`} school={school} />
        ))}
      </Grid>
    </Grid>
  );
};

export default React.memo(Schools);
