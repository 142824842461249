import Grid from "@mui/material/Grid";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Typography from "@mui/material/Typography";
import React from "react";

export type ComponentProps = {
  title: string;
  options: string[];
  Icon?: React.ReactNode;
};

const OptionsList = ({ title, options, Icon }: ComponentProps) => {
  return (
    <>
      <Typography variant="h2" sx={{ textAlign: "left", paddingTop: 4 }}>
        {title}
      </Typography>
      <List>
        {options.map((req, index) => {
          return (
            <ListItem key={`optionList-${title}-${index}`}>
              <ListItemText
                primary={
                  <Grid
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "flex-start",
                    }}
                  >
                    {Icon && Icon}
                    <Typography sx={{ textAlign: "left", marginLeft: 2 }}>
                      {req}
                    </Typography>
                  </Grid>
                }
              />
            </ListItem>
          );
        })}
      </List>
    </>
  );
};

export default React.memo(OptionsList);
