import React from "react";
import { useParams, useNavigate } from "react-router-dom";
import { licenses } from "../data";
import Grid from "@mui/material/Grid";
import Logo from "../img/main-logo.png";
import OptionsList from "./list/OptionsList";
import Footer from "../sections/footer";
import License from "../sections/licenses/license/License";
import NotFoundPage from "../notFoundPage";
import { common } from "@mui/material/colors";
import { Button, LinearProgress, Typography } from "@mui/material";

import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import AutoStoriesIcon from "@mui/icons-material/AutoStories";

const LicensePage = () => {
  const { license } = useParams();
  const navigate = useNavigate();
  const selectedLicense = licenses.find((l) => l.name === license);

  if (!selectedLicense) return <NotFoundPage />;

  return (
    <>
      <Grid
        sx={{
          display: "flex",
          flexDirection: "row",
          flexWrap: "wrap",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
          paddingTop: 10,
          paddingBottom: 10,
          backgroundColor: "#272727",
        }}
      >
        <Grid sx={{ maxWidth: "800px" }}>
          <img
            src={Logo}
            style={{ width: "100%" }}
            alt="Autoescola tramuntana logo"
          ></img>
        </Grid>
        <Grid>
          <License license={selectedLicense}></License>
        </Grid>
      </Grid>
      <Grid sx={{ backgroundColor: common.white, padding: 5 }}>
        <Typography
          variant="h1"
          sx={{ textAlign: "left", textTransform: "capitalize" }}
        >
          Permís {selectedLicense.name}
        </Typography>
        <LinearProgress variant="determinate" value={100} />
        <OptionsList
          title="Requisits"
          options={selectedLicense.requirements || []}
          Icon={<ArrowForwardIosIcon />}
        />
        <OptionsList
          title="Exàmens"
          options={selectedLicense.exams || []}
          Icon={<AutoStoriesIcon />}
        />
        <Grid sx={{ display: "flex", justifyContent: "center" }}>
          <Button
            variant="contained"
            color="primary"
            onClick={() => navigate("/")}
          >
            Tornar
          </Button>
        </Grid>
      </Grid>
      <Footer />
    </>
  );
};

export default React.memo(LicensePage);
